<template>
  <div class="absolute top-0 w-48 left-0 m-2 mb-12 p-2 text-gray-200 flex z-50 bg-control-gray rounded-lg font-mono text-xs">
    <div class="block">
      <h5 class="font-bold uppercase mt-1 pt-1">
        Camera
      </h5>
      <ul class="list-disc ml-4">
        <li>
          Position
          <div class="text-gray-500">
            <span class="block">{{ camPos.x }}</span>
            <span class="block">{{ camPos.y }}</span>
            <span class="block">{{ camPos.z }}</span>
          </div>
        </li>
        <li>
          Rotation
          <div class=" text-gray-500">
            <span class="block">{{ camRot.x }}</span>
            <span class="block">{{ camRot.y }}</span>
            <span class="block">{{ camRot.z }}</span>
          </div>
        </li>
      </ul>
      <h5 class="font-bold uppercase mt-1 pt-1">
        Controls
      </h5>
      <ul class="list-disc ml-4">
        <li>
          Target
          <div class="text-gray-500">
            <span class="block">{{ controlTarget.x }}</span>
            <span class="block">{{ controlTarget.y }}</span>
            <span class="block">{{ controlTarget.z }}</span>
          </div>
        </li>
      </ul>
      <h5
        class="cursor-pointer mt-1 pt-1 border-t border-gray-500 text-gray-100"
        @click.prevent="reset"
      >
        Reset
      </h5>
      <!-- <h5 class="cursor-pointer mt-1 pt-1 text-gray-100" @click.prevent="toggleHelpers">Toggle Helpers</h5> -->
      <h5
        class="cursor-pointer mt-1 pt-1 border-t border-gray-500 text-gray-100"
        @click.prevent="close"
      >
        Close
      </h5>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DebugMenu',

  props: {
    gui: {
      type: Object,
      required: true
    }
  },

  computed: {

    controls () {
      return this.gui.controls
    },

    controlTarget () {
      return this.controls.threeControls.target
    },

    threeCamera () {
      return this.gui.camera.threeCamera
    },

    camPos () {
      return this.threeCamera.position
    },

    camRot () {
      return this.threeCamera.rotation
    }
  },

  methods: {
    reset () {
      this.controls.reset()
    },
    close () {
      this.$emit('close')
    },
    toggleHelpers () {
      this.gui.toggleHelpers()
    }
  }
}
</script>
